<script setup>
import { ref, watch } from 'vue';
import InputLabel from './InputLabel.vue';
import TextInput from './TextInput.vue';
import InputError from './InputError.vue';
import TextAreaInput from './TextAreaInput.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
  modelValue: [String, Number, FileList, Array, Date],
  errors: String,
  inline: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  wrapperClass: {
    type: String,
    default: 'mt-4',
  },
  type: {
    type: String,
    default: 'text',
  },
  label: String,
  id: {
    type: String,
    required: true,
  },
  error: String,
  required: {
    type: Boolean,
    default: false,
  },
  allowMultiple: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    required: false
  },
  accept: {
    type: String,
    default: '*',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  autocomplete: String,
});


const emit = defineEmits(['update:modelValue']);

const inputValue = ref(props.modelValue);

// Watch for changes in modelValue to sync with inputValue
watch(
  () => props.modelValue,
  (newValue) => {
    console.log('newValue', newValue);
    console.log(inputValue.value);
    inputValue.value = newValue;
  }
);

// Watch for changes in inputValue to emit modelValue updates
watch(inputValue, (newValue) => {
  emit('update:modelValue', newValue);
});


</script>

<template>
  <div :class="wrapperClass">

    <InputLabel :for="id" :value="label" />

    <div v-if="type === 'wysiwyg'">
      <TextAreaInput :id="id" v-model="inputValue" :type="type" class="mt-1 block w-full" :required="required"
        :disabled="disabled" />
    </div>

    <div v-else-if="type === 'date'">
      <VueDatePicker :id="id" v-model="inputValue" format="dd/MM/yyyy" :type="type" class="mt-1 block w-full"
        :enable-time-picker="false" :required="required" :disabled="disabled" auto-position="bottom" />
    </div>

    <div v-else-if="type === 'datetime'">
      <VueDatePicker :id="id" v-model="inputValue" :type="type" format="dd/MM/yyyy HH:mm" minutes-increment="15"
        :locale="en - GB" class="mt-1 block w-full" :inline="inline" time-picker-inline :auto-apply="inline"
        :required="required" :disabled="disabled" />
    </div>
    <div v-else-if="type === 'select'">
      <select :id="id" v-model="inputValue" :required="required" :disabled="disabled"
        class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-gray-700">
        <option value="">Select an option</option>
        <option v-for="option in options" :key="option" :value="option.value" :selected="inputValue === option.value">{{
          option.label }}</option>
      </select>
    </div>

    <div v-else-if="type === 'file'">

      <slot />

      <label
        class="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150 mt-4">
        <input :id="id" :type="type" class="hidden" :accept="accept" :required="required" :disabled="disabled"
          :multiple="allowMultiple" @change="inputValue = $event.target.files" />
        <svg v-if="loading" class="animate-spin h-3 w-3 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
        <span>Upload {{ allowMultiple ? 'files' : 'a file' }}</span>
      </label>

    </div>
    <TextInput v-else :id="id" v-model="inputValue" :type="type" :autocomplete="autocomplete" :placeholder="placeholder"
      class="mt-1 block w-full text-background placeholder:text-foreground-secondary"
      :class="{ '!w-16 !h-8 rounded': type === 'color' }" :required="required" :disabled="disabled" />
    <InputError class="mt-2" :message="error" />
  </div>
</template>

<style>
.dp__flex_display {
  display: block;
}

.dp--tp-wrap {
  max-width: none;
}
</style>
